import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser } from "@coreui/icons";
import { useNavigate } from "react-router";
import { forgotPassword } from "src/api/change-pass/forgotPass";
const ForgotPass = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const res = await forgotPassword({ email: email });
    // console.log(res);
    if (res?.data?.statusCode == 200) {
      setLoading(false);
      // console.log(res.data);
      alert(res.data.statusMessage);
      //   navigate("/");
    } else if (res.remote == "failure") {
      setLoading(false)
      alert(res.errors.errors);
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit}>
                  <h1>Find Your Account</h1>
                  <p className="text-medium-emphasis">
                    Please enter your email address to search for your account.
                  </p>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      type="email"
                      placeholder="Email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </CInputGroup>
                  <div className="d-flex justify-content-end">
                    <div className="p-2">
                      <CButton color="light" onClick={() => navigate("/login")}>
                        Cancel
                      </CButton>
                    </div>
                    <div className="p-2">
                      <CButton color="primary" type="submit">
                        {loading ? (
                          <>
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </>
                        ) : (
                          "Search"
                        )}
                      </CButton>
                    </div>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ForgotPass;
