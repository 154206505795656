import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import { signUp } from "../../../api/sign-up/sign-up";
import { isObjEmpty } from "src/utils/emptyObj";
import { useLocation, useNavigate } from "react-router";
import { changePassword } from "src/api/change-pass/changePassword";
import { resetPassword } from "src/api/change-pass/resetPass";
import { getLastSegment } from "src/utils/lastSegmentFromUrl";
const ResetPassword = () => {
  const [regData, setRegistrationData] = useState({
    newPassword: "",
    repeat_password: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  
  let token = getLastSegment(location.pathname);
  
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setRegistrationData({ ...regData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = await resetPassword(regData, token);
    // console.log(data);
    if (data.remote == "success") {
      alert(data.data.statusMessage);
      navigate("/");
    } else {
      // console.log(data.errors)
      alert(data.errors.errors);
    }
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit}>
                  <h1>Reset Password</h1>
                  <p className="text-medium-emphasis">Reset Your Password</p>

                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      required
                      placeholder="Type New Password"
                      autoComplete="new-password"
                      name="newPassword"
                      value={regData.newPassword}
                      onChange={handleChange}
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      required
                      placeholder="Repeat New Password"
                      autoComplete="new-password"
                      name="repeat_password"
                      value={regData.repeat_password}
                      onChange={handleChange}
                    />
                  </CInputGroup>
                  <div className="d-grid">
                    <CButton color="success" type="submit">
                      Reset Password
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
