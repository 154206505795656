import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./comman/private";
import "./scss/style.scss";
import ForgotPass from "./views/pages/changepassword/ForgotPass";
import ResetPassword from "./views/pages/changepassword/ResetPass";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const ChangePass = React.lazy(() =>
  import("./views/pages/changepassword/ChangePass")
);

class App extends Component {
  render() {
    return (
      <Router>
        <Suspense fallback={loading}>
          <Routes>
            <Route
              exact
              path="/login"
              name="Login Page"
              element={<Login />}
              // element={<Login/>}
            />
            <Route
              exact
              path="/register"
              name="Register Page"
              element={<Register />}
            />
            <Route
              exact
              path="/password"
              name="Change Password"
              element={<ChangePass />}
            />
            <Route
              exact
              path="/login/forgot"
              name="Forgot Password"
              element={<ForgotPass />}
            />
            <Route
              exact
              path="/reset/:id"
              name="Reset Password"
              element={<ResetPassword />}
            />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route
              path="*"
              name="Home"
              element={
                <PrivateRoute>
                  <DefaultLayout />
                </PrivateRoute>
              }
            />
          </Routes>
        </Suspense>
      </Router>
    );
  }
}

export default App;
