export const API_ROUTES = {
    SIGN_UP: {
        REGISTER: 'register',
    },
    SIGN_IN: {
        LOGIN:'admin/login',
        CHANGE_PASS:'changePassword',
        FORGOT_PASS:'forgotPassword',
        RESET_PASS:'resetPassword'
    },
    ADD_CATEGORY: {
        ADD: 'category'
    },
    COPY_CATEGORY: {
        COPY: 'category/createCopy',
        CHART: 'category/chart'
    },
    UPDATE_CATEGORY: {
        UPDATE:'category/update'
    },
    ALL_CATEGORY: {
        GET: 'category'
    },
    DELETE_CATEGORY: {
        DELETE:'category/delete'
    },
    UPLOAD_IMAGE:{
        IMG:'signedUrlUpload'
    },
    PARTICIPANT_ROUTE: {
        PARTICIPANT: 'participant',
        UPDATE:'participant/update',
        DELETE:'participant/delete'
    },
    ADD_PARTICIPANT_IN_CAT:{
        ADD:'category/addRemoveParticiantsFromCategory'
    },
    SETTING:{
        GET:'setting',
        POST:'setting',
        UPDATE:'setting/update',
        DELETE:'setting/delete'
    },
    REPORT:{
        USER_REPORT:'usersReport',
        USERS:'users'
    },
    DASHBOARD:{
       COUNT:'userAndVoteCount',
       TOPLIST:'categoryWithParticipant'
    },
    METAROUTE:{
        GET:'siteMetadata',
        PUT:'siteMetadata/update'
    },
    FORM:{
        GET:'form',
        PUT:'form/update'
    },
    VOTE:{
        GET:'voteSetting',
        PUT:'voteSetting/update',
        DELETE: 'vote'
    },
    GOOGLE_TAG:{
        GET:'googleManager',
        PUT:'googleManager/update'
    },
    ORDER_UPDATE:{
        UPDATE:'category/updateOrder'
    }



}