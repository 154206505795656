import { API_ROUTES } from "../API_ROUTES";
import { apiRoot } from "../root";

export const resetPassword = (data,token) => {  
    return apiRoot({ 
        url: `/${API_ROUTES.SIGN_IN.RESET_PASS}/${token}`,
        method: 'POST', 
        data,
        
        
    });
}