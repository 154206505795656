import { API_ROUTES } from "../API_ROUTES";
import { apiRoot } from "../root";

export const forgotPassword = (data) => {  
    return apiRoot({ 
        url: `/${API_ROUTES.SIGN_IN.FORGOT_PASS}`,
        method: 'POST', 
        data,
        
    });
}